/* You can add global styles to this file, and also import other style files */
    @import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

    .owl-dt-inline-container .owl-dt-calendar, .owl-dt-popup-container .owl-dt-calendar{
        height: auto;
    }

    .toast-container{
    position: fixed;
    z-index: 999999999999999999999999999999;
    /* bottom: 0; */
    /* left: 0; */
    right: 12px;
    bottom: 12px;
    }

     .toast-container .ngx-toastr {
        position: relative;
        overflow: hidden;
        margin: 0 0 6px;
        padding: 15px 15px 15px 50px;
        width: 300px;
        border-radius: 3px 3px 3px 3px;
        background-position: 15px;
        background-repeat: no-repeat;
        background-size: 24px;
        box-shadow: 0 0 12px #999;
        color: #fff;
    }
     .toast-success {
        background-color: #51a351;
    }
    .ngx-toastr {
        background-color: #030303;
        pointer-events: auto;
    }
    .toast-error {
         background-color: #bd362f;
    }
     .toast-container * {
        box-sizing: border-box;
    }

    .form-control.is-invalid, .was-validated .form-control:invalid {
        border-color: #dc3545 !important;
    }
    select.form-control:not(select.form-control-sm):not(select.form-control-lg) {
        padding: 9.5px 14px !important;
    }
    .cdk-global-scrollblock {
        position: inherit !important;
        width: initial !important;
        overflow-y: scroll;
    }
    .cdk-overlay-container{
        z-index: 9999;
    }